import { AppBaseApi } from '../api';
import { Firmware, IFirmwareVersionView } from '../../model/interfaces/firmware';
import { AxiosResponse } from 'axios';
export class FirmwaresService extends AppBaseApi {

    public getAll(): Promise<AxiosResponse<Firmware[]>> {
        return this.axios.get(this.basePath + '/firmwares')
    }

    public downloadById(id: number): Promise<AxiosResponse<any>> {
        return this.axios.get(this.basePath + `/firmwares/${id}/download`)
    }

    public getById(id: number): Promise<AxiosResponse<Firmware>> {
        return this.axios.get(this.basePath + `/firmwares/${id}`)
    }

    public update(firmware: Firmware): Promise<AxiosResponse<Firmware>> {
        return this.axios.put(this.basePath + `/firmwares/${firmware.Id}`, {Data: firmware.Data, FileName: firmware.FileName, UploadFile: firmware.UploadFile, Version: firmware.Version, Id: firmware.Id, Description: firmware.Description, Date: firmware.Date}, { headers: { "Content-Type": "multipart/form-data" } });
    }

    public create(firmware: Firmware): Promise<AxiosResponse<Firmware>> {
        return this.axios.post(this.basePath + `/firmwares`, {Data: firmware.Data, FileName: firmware.FileName, UploadFile: firmware.UploadFile, Version: firmware.Version, Description: firmware.Description, Date: firmware.Date}, { headers: { "Content-Type": "multipart/form-data" } })
    }

    public getDownloadFirmwareUrl(id: number): Promise<AxiosResponse<Blob>> {
        return this.axios.get(`${this.basePath}/firmwares/${id}/download`, { responseType: 'blob' });
    }
}
