import { FunctionComponent } from 'react';
import styles from '../../layout/SubHeader/SubHeader.module.scss';
import { AppRoute } from '../../../routes';
import { Link } from 'react-router-dom';

interface Props {
  routes?: AppRoute[];
  isChildRoute?: boolean;
}

const SubHeader: FunctionComponent<Props> = (props: Props) => {
  const { routes, isChildRoute } = props;
  const GoBackToUtils = () => {
    window.location.replace('https://utils.umojodev.com/');
  };
  return (
    <div className={styles.subHeaderBox}>
      {
        routes && !isChildRoute ? <div className={styles.navigationTitles}>{routes[0]?.name}</div> : <div>{routes ? routes.map((r:AppRoute, index, array) => {
          return (
            <span key={'nav' + index}>
              {index === 0 ? (
                <Link key={'navName' + index} className={styles.navigationChainLink} to={r.path}>{(r.state ? r.state : '') + ' ' + r?.name}</Link>
              ) : (
                <span key={'navName' + index} className={styles.navigationChain}>{(r.state ? r.state : '') + ' ' + r?.name}</span>
              )}
              {index + 1 === array.length ? '' : <span key={'navArrow' + index} className={styles.navigationChain}>{'>'}</span>}
            </span>
          )
        }) : ''}</div>
      }
      <div>
        <button className={styles.actionButtonGray} onClick={GoBackToUtils}>Back to Umojo Utils</button>
      </div>
    </div>
  );
};

export default SubHeader;
