import getApi, { getApiUrl } from './api';
import { IntercomsService } from '../intercoms/intercoms-service';
import { FirmwaresService } from '../firmware/firmware-service';
import { AuditLogService } from '../auditLog/audit-log-service';

let _apiClient: IApiClient | null = null;


export interface IApiClient {
  intercoms: IntercomsService;
  firmware: FirmwaresService;
  audit: AuditLogService;
}
export const apiClient = () => {
  if (!_apiClient) {
    const apiUrl = getApiUrl() ?? '';
    const api = getApi();

    _apiClient = {
      intercoms: new IntercomsService(apiUrl, api),
      firmware: new FirmwaresService(apiUrl, api),
      audit: new AuditLogService(apiUrl, api),
    };
  }

  return _apiClient;
}
