import { FunctionComponent } from 'react';

import styles from './GlobalLoader.module.scss';
import logo from '../../../assets/loaderTransparent.gif';
import ApiLoaderShow from '../../../services/api/api-loader-interceptor';

const GlobalLoader: FunctionComponent = () => {
  if (ApiLoaderShow()) {
    return (
      <div className={styles.cssloadContainer}>
        <img className={styles.spinnerPosition} src={logo} alt={'loading...'} />
      </div>
    );
  } else {
    return null;
  }
};

export default GlobalLoader;
