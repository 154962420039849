import { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';

import { DropDownButton, DropDownButtonItem } from '@progress/kendo-react-buttons';
import { AppBar, AppBarSection, AppBarSpacer } from '@progress/kendo-react-layout';

import styles from './header.module.scss';
import logo from '../../../assets/UmojoLogo.svg';
import { useAppSelector } from '../../../store/hooks';
import { logout, selectUserProfile } from '../../../features';

const Header: FunctionComponent = () => {
  const userProfile = useAppSelector(selectUserProfile);

  const onItemClick = (text: string) => {
    switch (text) {
      case 'Logout': {
        logout();
        break;
      }
      default: {
        break;
      }
    }
  };

  const theme = 'dark';

  return (
    <header className={styles.header}>
      <AppBar className={styles.appBar} themeColor={theme} style={{ backgroundColor: '#223B54' }}>
        <AppBarSection>
          <NavLink to='/'>
            <img className={styles.logo} src={logo} alt='logo' />
          </NavLink>
        </AppBarSection>

        <AppBarSection>
          <ul>
            <li>
              <NavLink className={styles.link} to='/intercoms'>
                Intercoms
              </NavLink>
            </li>
            <li>
              <NavLink className={styles.link} to='/firmware'>
                Firmwares
              </NavLink>
            </li>
            <li>
              <NavLink className={styles.link} to='/audit-log'>
                Audit Log
              </NavLink>
            </li>
          </ul>
        </AppBarSection>

        <AppBarSpacer />

        <AppBarSection>
          <DropDownButton
            icon='arrow-60-down'
            buttonClass={styles.dropdownIcon}
            themeColor='light'
            fillMode='link'
            rounded='small'
            text={userProfile?.name ? userProfile?.name : 'User'}
            popupSettings={{ popupClass: styles.dropdownPopup }}
            onItemClick={(evt) => onItemClick(evt.item.text)}
          >
            <DropDownButtonItem text='Logout' icon='logout' />
          </DropDownButton>
        </AppBarSection>
      </AppBar>
    </header>
  );
};

export default Header;
