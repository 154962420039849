import { FunctionComponent, useEffect, useState } from 'react';
import styles from './FirmwareDetail.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { Field, FieldWrapper, Form, FormElement } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';
import { apiClient, notifications } from '../../../services';
import { Firmware } from '../../../model/interfaces/firmware';
import DragNDrop from '../../../components/common/DragNDrop/DragNDrop';
import { ValidationService } from '../../../services/validation-service';
import { DragNDropFileService } from '../../../components/common/DragNDrop/DragNDropFileService';

const validationMessage = ValidationService.defaultFieldValidationMessage();

const VersionField: FunctionComponent = (fieldRenderProps: any) => {
  const { validationMessage, visited, label, id, valid, disabled, ...others } =
    fieldRenderProps;
  const showValidationMessage = visited && validationMessage;
  return (
    <FieldWrapper>
      <Label
        className={styles.fieldLabel}
        editorId={id}
        editorValid={valid}
      >
        {label}
        <sup className={styles.requiredStar}></sup>
      </Label>
      <div className='k-form-field-wrap'>
        <Input
          maxLength={20}
          valid={valid}
          type={'text'}
          id={id}
          disabled={disabled}
          {...others}
        />
        {showValidationMessage && <div className={styles.errorMessage}>{validationMessage}</div>}
      </div>
    </FieldWrapper>
  );
};

const DescriptionField: FunctionComponent = (fieldRenderProps: any) => {
  const { validationMessage, visited, label, id, valid, disabled, ...others } =
    fieldRenderProps;
  return (
    <FieldWrapper>
      <Label
        className={styles.fieldLabel}
        editorId={id}
        editorValid={valid}
      >
        {label}
      </Label>
      <div className='k-form-field-wrap'>
        <Input
          maxLength={55}
          valid={valid}
          type={'text'}
          id={id}
          disabled={disabled}
          {...others}
        />
      </div>
    </FieldWrapper>
  );
};


const FirmwareDetailPage: FunctionComponent = () => {
  const currentLoc = useLocation();
  const navigate = useNavigate();
  const notif = notifications;
  const [firmwareDetail, setFirmwareDetail] = useState<Firmware>();
  const [files, setFiles] = useState<any>();
  const [fileInfo, setFileInfo] = useState<{ fileName: string, result: any }>({ fileName: '', result: null });

  const isEditState = currentLoc.state === 'Edit';

  if (!firmwareDetail && !isEditState) {
    setFirmwareDetail({ Id: 0, Data: '', Description: '', Version: '', FileName: '', UploadFile: '', Date: new Date()});
  }

  const handleSubmit = (dataItem: any) => {
    if (dataItem && fileInfo) {
      dataItem.FileName = fileInfo.fileName;
      dataItem.UploadFile = fileInfo.result;

      if (isEditState) {
        apiClient().firmware.update(dataItem).then((response) => {
          notif.success('The item was updated successfully.')
          navigate('/firmware')
        });
      }
      if (!isEditState) {
        apiClient().firmware.create(dataItem).then((response) => {
          notif.success('The item was created successfully.')
          navigate('/firmware')
        });
      }
    }
  };

  useEffect(() => {
    if (isEditState) {
      apiClient().firmware.getById(Number(currentLoc.pathname.split('/')[2])).then((response) => {
        setFirmwareDetail(response.data);
        const fileName = response.data.FileName ?? '';
        const fileBlobData = response.data.Data ?? '';
        const blob = DragNDropFileService.b64toBlob(fileBlobData, 'application/octet-stream', 15728640);
        const file = new File([blob], fileName);
        setFileInfo({ fileName: fileName, result: file });
      });
    }
  }, []);

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.pageTitle}>{currentLoc.state} Firmware</div>
      <div className={styles.pageWrapper}>
        <Form
          onSubmit={handleSubmit}
          initialValues={firmwareDetail}
          key={JSON.stringify(firmwareDetail)}
          render={(formRenderProps) => (
            <FormElement style={{ paddingRight: 40 }}>
              <div className={styles.panelRowSpaceBetween} style={{ alignItems: 'flex-start' }}>
                <div style={{ width: '45%', marginRight: 20 }}>
                  <Field
                    id={'versionId'}
                    name={'Version'}
                    label={'Version'}
                    component={VersionField}
                    validator={(value) => !!value ? '' : validationMessage}
                  />
                  <Field
                    id={'descriptionId'}
                    name={'Description'}
                    label={'Description'}
                    component={DescriptionField}
                  />
                </div>
                <div style={{ width: '45%' }}>
                  <FieldWrapper>
                    <Label
                      className={styles.fieldLabel}
                    >
                      Firmware File
                      <sup className={styles.requiredStar}></sup>
                    </Label>
                    <DragNDrop onFilesSelected={setFiles} onSetFileInfo={setFileInfo} fileName={firmwareDetail?.FileName} />
                  </FieldWrapper>
                </div>

              </div>
              <div className='k-form-buttons' style={{ justifyContent: 'end' }}>
                <button
                  type={'button'}
                  className={styles.actionButtonGray}
                  onClick={(() => {navigate('/firmware')})}
                >
                  Cancel
                </button>
                <button
                  type={'submit'}
                  className={styles.saveBtn}
                  disabled={!formRenderProps.allowSubmit || !fileInfo.result}
                >
                  Save
                </button>
              </div>
            </FormElement>
          )}
        />
      </div>
    </div>
  );
};

export default FirmwareDetailPage;
