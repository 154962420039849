import { AppBaseApi } from '../api';
import { AxiosResponse } from 'axios/index';
import { Audit } from '../../model/interfaces/audit';
import { PagedListResponse } from '../api/pageListResponse';
import { PagedListRequest } from '../api/pagedListRequest';

export class AuditLogService extends AppBaseApi {

  public getAll(search: PagedListRequest): Promise<AxiosResponse<PagedListResponse<Audit[]>>> {
    return this.axios.post(this.basePath + '/audit', search)
  }
}
