import React, { FunctionComponent, Suspense, useEffect, useState } from 'react';
import Header from './header/Header';
import styles from './layout.module.scss';
import { useLocation } from 'react-router-dom';
import { RoutesList, AppRoute } from '../../routes';
import { useIsAuthenticated } from '@azure/msal-react';
import SubHeader from './SubHeader/SubHeader';

interface Props {
  children: React.ReactNode;
}

const Layout: FunctionComponent<Props> = ({ children }) => {
  let currentLoc = useLocation();
  const routerList = RoutesList();
  const [currentRoutes, setCurrentRoutes] = useState<AppRoute[]>();

  const isAuthenticated = useIsAuthenticated();

  const isChildRoute = currentLoc.pathname.split('/').length > 2

  useEffect(() => {
    const route = isChildRoute ? routerList.find((item: AppRoute) => item.path.split('/')[1] === currentLoc.pathname.split('/')[1])
      : routerList.find((item: AppRoute) => item.path === currentLoc.pathname);


    if (route && isChildRoute && route.children && route.children.length > 0) {
      let routesList: any = route.children.map((r: AppRoute) => {
        return {...r, state: currentLoc.state}
      })
      routesList.unshift(route);
      setCurrentRoutes(routesList)
    }

    if (!isChildRoute && route) {
      setCurrentRoutes([route])
    }

    if (route && route.title) {
      document.title = route.title
    }
  }, [currentLoc])


  return (
    <div className={styles.app}>
      {isAuthenticated ?
        <><Header /><SubHeader routes={currentRoutes} isChildRoute={isChildRoute} /></>
        : null}

      <Suspense>
        <main className={styles.main}>{children}</main>
      </Suspense>
    </div>
  );
}

export default Layout;
