import { FunctionComponent, useEffect, useState } from 'react';
import styles from './DragNDrop.module.scss';
import CrossIcon from '../../../assets/icons/CrossIcon.svg';
import { DragNDropFileService } from './DragNDropFileService';
import { notifications } from '../../../services';


interface Props {
  onFilesSelected: any;
  onSetFileInfo: any;
  width?: any;
  height?: any;
  acceptsFormats?: string;
  fileName?: string;
}

const DragNDrop: FunctionComponent<Props> = ({
                                               onFilesSelected,
                                               onSetFileInfo,
                                               width,
                                               height,
                                               acceptsFormats,
                                               fileName,
                                             }) => {
  const [files, setFiles] = useState<File[]>([]);
  const [prevFileName, setPrevFileName] = useState<string>(fileName ? fileName : '');
  const handleFileChange = (event: any) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const newFiles = Array.from(selectedFiles);
      setFiles((prevFiles: any) => [...prevFiles, ...newFiles]);
    }
  };
  const handleDrop = (event: any) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    const droppedFileType = droppedFiles[0].type;
    if (droppedFiles.length > 0) {
      if (droppedFileType !== 'application/octet-stream') {
        notifications.error('Firmware file format should be .bin')
      } else {
        const newFiles = Array.from(droppedFiles);
        setFiles((prevFiles: any) => [...prevFiles, ...newFiles]);
      }
    }
  };

  const handleRemoveFile = (index?: number) => {
    if (index === -1) {
      setPrevFileName('');
      onSetFileInfo({fileName: null, result: null })
      return
    }
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    onFilesSelected(files);
    if (files && files.length > 0) {
      const contentType: string = files[0].type;
      const fName: string = files[0].name;

      DragNDropFileService.getBase64(files, (res: any) => {
        const bs64: string = res.split('base64,')[1]
        let blob: any = DragNDropFileService.b64toBlob(bs64, contentType, 15728640);
        const file = new File([blob], fName)
        onSetFileInfo({fileName: files[0].name, result: file })
      })
    }

  }, [files, onFilesSelected]);

  return (
    <section className={styles.dragDropZone} style={{ width: width, height: height }}>
      {files.length === 0 && !prevFileName && (
      <div
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()}
      >
        <>
          <div className={styles.uploadInfo}>
            <div>
              <p>Drop files here to upload</p>
              <p>
                Limit 15MB per file. Supported files: {acceptsFormats ? acceptsFormats : '.bin'}
              </p>
            </div>
          </div>
          <input
            type='file'
            hidden
            id='browse'
            onChange={handleFileChange}
            accept={acceptsFormats ? acceptsFormats : '.bin'}
          />
          <label htmlFor='browse' className={styles.browseBtnWrapper}>
            <div className={styles.actionBrowseBtn}>Browse files</div>
          </label>
        </>
      </div>
        )}
      {files.length > 0 && (
        <div>
          {files.map((file, index) => (
            <div key={index} className={styles.fileNameBox}>
              {file ? file.name : ''}
              <img onClick={() => handleRemoveFile(index)} src={CrossIcon} alt='delete' />
            </div>
          ))}
        </div>
      )}
      {files.length === 0 && prevFileName && (
        <div className={styles.fileNameBox}>
          {fileName}
          <img onClick={() => handleRemoveFile(-1)} src={CrossIcon} alt='delete' />
        </div>
      )

      }
    </section>
  );
};

export default DragNDrop;
