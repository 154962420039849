import { lazy, useEffect, useRef, useState } from 'react';
import './App.scss';
import Layout from './components/layout/Layout';
import { HashRouter, Route, Routes, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import NotFound from './pages/NotFound';
import AuthenticatedRoute from './components/auth/AuthenticatedRoute';
import { MsalProvider } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { appSettingsActions, selectAppSettings } from './features/app-settings/app-settings-slice';
import createMsalInstance from './features/auth/msal-instance';
import { userProfileActions } from './features';
import { CustomNavigationClient } from './utils/NavigationClient';
import { store } from './store';
import { Provider } from 'react-redux';
import { RouteConfig } from './routes';
import GlobalLoader from './components/common/GlobalLoader/GlobalLoader';
import FirmwareDetailPage from './pages/firmware/FirmwareDetail/FirmwareDetailPage';


const HomePage = lazy(() => import('./pages/home/HomePage'));
const FirmwarePage = lazy(() => import('./pages/firmware/FirmwarePage'));
const IntercomsPage = lazy(() => import('./pages/intercoms/IntercomsPage'));
const AuditLog = lazy(() => import('./pages/auditLog/AuditLogPage'));

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <HashRouter>
        <App />
        <GlobalLoader></GlobalLoader>
      </HashRouter>
    </Provider>
  );
};

const App = () => {

  const msalRef = useRef<IPublicClientApplication | null>(null);
  const [msal, setMsal] = useState<IPublicClientApplication | null>(null);
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const settings = useAppSelector(selectAppSettings).data;
  const routeConfig = RouteConfig();

  useEffect(() => {
    dispatch(appSettingsActions.fetchSettings());
  }, [dispatch]);

  useEffect(() => {
    if (settings && !msalRef.current) {
      const msalInstance = createMsalInstance(settings, (account) => {
        setTimeout(() => {
          dispatch(
            userProfileActions.setProfile({
              username: account?.username || '',
              name: account?.name || '',
            }),
          );
        }, 1);
      });

      msalInstance.handleRedirectPromise().then((res) => {
        if (res !== null) {
          dispatch(
            userProfileActions.setProfile({
              username: res.account?.username || '',
              name: res.account?.name || '',
            }),
          );
        }
      });

      const navigationClient = new CustomNavigationClient(history);
      msalInstance.setNavigationClient(navigationClient);
      msalRef.current = msalInstance;
      setMsal(msalInstance);
    }
  }, [dispatch, history, settings]);

  if (!settings || !msal) {
    return <div></div>;
  }

  return (
    <MsalProvider instance={msal}>
        <Layout>
          <Routes>
            <Route element={<AuthenticatedRoute requireAuth={false} redirectPath='/intercoms' />}>
              <Route path={routeConfig.Home.path} element={<HomePage />} />
            </Route>

            <Route element={<AuthenticatedRoute requireAuth={true} redirectPath='/' />}>
              <Route path={routeConfig.Intercoms.path} element={<IntercomsPage />} />
              <Route path={routeConfig.Firmware.path} element={<FirmwarePage />} />
              <Route path={routeConfig.Firmware.children[0].path} element={<FirmwareDetailPage />} />
              <Route path={routeConfig.AuditLogs.path} element={<AuditLog />} />
            </Route>

            <Route path='*' element={<NotFound />} />
          </Routes>
        </Layout>

        <ToastContainer autoClose={2000} position='bottom-right' />
    </MsalProvider>
  );
}

export default AppWrapper;
